var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    _vm._g(
      {
        attrs: {
          "close-on-content-click": false,
          "max-width": "400",
          "min-width": "140",
          "offset-y": true
        },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function(slotData) {
                return [
                  _vm.$scopedSlots.activator
                    ? _vm._t("activator", null, null, slotData)
                    : _c(
                        "v-btn",
                        _vm._g({ attrs: { text: "" } }, slotData.on),
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "primary", small: "" } },
                            [_vm._v("\n        fal fa-list\n      ")]
                          )
                        ],
                        1
                      )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.menuOpened,
          callback: function($$v) {
            _vm.menuOpened = $$v
          },
          expression: "menuOpened"
        }
      },
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        _vm._g({ staticClass: "multi-select" }, _vm.$listeners),
        [
          _c(
            "v-row",
            { staticClass: "px-2", attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "a",
                  {
                    staticClass: "caption mr-1",
                    attrs: { color: "secondary" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.selectAll.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("labels.selectAll")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("v-spacer"),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "a",
                  {
                    staticClass: "caption mr-1",
                    attrs: { color: "secondary" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.clearAll.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("labels.clearAll")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            _vm._g({ style: _vm.listStyle }, _vm.$listeners),
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { multiple: "" },
                  on: { change: _vm.onSelectedItemsChange },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _vm._l(_vm.selection, function(item, i) {
                    return [
                      _c("v-list-item", {
                        key: i,
                        attrs: {
                          "active-class": "selected",
                          value: item.value
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var active = ref.active
                                return [
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      staticClass: "mr-n1",
                                      attrs: { height: "30" }
                                    },
                                    [
                                      active
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-n2",
                                              attrs: {
                                                color: "secondary",
                                                size: "14"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-check-square\n                "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-n2",
                                              attrs: {
                                                color: "grey",
                                                size: "14"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  far fa-square\n                "
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "body-2" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.label) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          !_vm.autoApply
            ? [
                _c("v-divider"),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { color: "accent", right: "", small: "" },
                        on: { click: _vm.onConfirmButtonClicked }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.apply")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }