<template>
  <v-menu
    v-model="menuOpened"
    :close-on-content-click="false"
    max-width="400"
    min-width="140"
    :offset-y="true"
    v-on="$listeners"
  >
    <template v-slot:activator="slotData">
      <slot
        v-if="$scopedSlots.activator"
        name="activator"
        v-bind="slotData"
      />
      <v-btn
        v-else
        text
        v-on="slotData.on"
      >
        <v-icon
          color="primary"
          small
        >
          fal fa-list
        </v-icon>
      </v-btn>
    </template>
    <v-card
      class="multi-select"
      v-on="$listeners"
    >
      <v-row
        class="px-2"
        dense
      >
        <v-col cols="auto">
          <a
            class="caption mr-1"
            color="secondary"
            @click.stop="selectAll"
          >
            {{ $t('labels.selectAll') }}
          </a>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <a
            class="caption mr-1"
            color="secondary"
            @click.stop="clearAll"
          >
            {{ $t('labels.clearAll') }}
          </a>
        </v-col>
      </v-row>
      <v-divider />
      <v-list
        :style="listStyle"
        v-on="$listeners"
      >
        <v-list-item-group
          v-model="selected"
          multiple
          @change="onSelectedItemsChange"
        >
          <template v-for="(item, i) in selection">
            <v-list-item
              :key="i"
              active-class="selected"
              :value="item.value"
            >
              <template #default="{ active }">
                <v-list-item-avatar
                  class="mr-n1"
                  height="30"
                >
                  <v-icon
                    v-if="active"
                    class="ml-n2"
                    color="secondary"
                    size="14"
                  >
                    far fa-check-square
                  </v-icon>
                  <v-icon
                    v-else
                    class="ml-n2"
                    color="grey"
                    size="14"
                  >
                    far fa-square
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <template v-if="!autoApply">
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mr-1"
            color="accent"
            right
            small
            @click="onConfirmButtonClicked"
          >
            {{ $t('labels.apply') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    autoApply: {
      default: false,
      type: Boolean
    },
    selection: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      height: 300,
      menuOpened: false,
      previouslySelected: [],
      selected: [],
      selectionConfirmed: false
    };
  },
  computed: {
    listStyle () {
      return {
        'max-height': `${this.height}px`
      };
    }
  },
  watch: {
    menuOpened (opened) {
      if (opened) {
        this.selectionConfirmed = false;
      } else {
        if (!this.selectionConfirmed) {
          this.selected = this.previouslySelected;
        }
      }
    }
  },
  mounted: function () {
    this.updateListHeight();
    window.addEventListener('resize', _.debounce(this.updateListHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateListHeight);
  },
  methods: {
    clearAll () {
      this.selected = [];
      if (this.autoApply) {
        this.onConfirmButtonClicked(false);
      }
    },
    onConfirmButtonClicked (closeMenu = true) {
      this.previouslySelected = this.selected;
      this.selectionConfirmed = true;
      this.$emit('selectionConfirmed', this.selected);
      if (closeMenu) {
        this.menuOpened = false;
      }
    },
    onSelectedItemsChange () {
      this.$emit('selectedItemsChanged', this.selected);
      if (this.autoApply) {
        this.onConfirmButtonClicked(false);
      }
    },
    selectAll () {
      this.selected = this.selection.map((s) => s.value);
      if (this.autoApply) {
        this.onConfirmButtonClicked(false);
      }
    },
    updateListHeight () {
      this.height = document.body.clientHeight / 2;
    }
  }
};
</script>

<style lang="scss">
.multi-select {
  .v-list {
    overflow-y: scroll;
    .v-list-item {
      &.selected {
        color: $secondary;
      }
    }
  }
}
</style>
